import React, { Fragment, useState } from 'react'
import './index.scss'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import { Parallax } from 'react-scroll-parallax'

import MainFrameCover from '../components/MainFrameCover'
import Intro from '../animations/Intro'
import { useInView } from 'react-intersection-observer'

import Slider from 'react-slick'

import Footer from '../components/Footer'

import SanitySlider from '../Sanity/SanitySlider'
import SanityVideo from '../Sanity/SanityVideo'
import SanityContent from '../Sanity/SanityContent'
import SanityImage from '../Sanity/SanityImage'

import Modal from '../components/Modal'
import SignUpOfferModal from '../components/Modal/MRUEnroll'
// import Modal from '../components/Modal'

let formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
})
let formatter2dig = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

function Page(props) {
  const { data, pageContext } = props

  const [refVideo, refVideoInView] = useInView({ triggerOnce: true })


  const [refAbout, refAboutInView] = useInView({ triggerOnce: true })
  // const aboutCopy = <>
  //   <h2 className='h1'>Our History</h2>
  //   <p>Famously dubbed the "Pizzapreneur", Peter grew up in Long Island, NY, working in the pizzeria business from his early teenage years. Peter pursued an undergraduate degree from Long Island's NYIT School of Hospitality Management, including a move abroad to Florence, Italy to study first hand in the motherland. Peter always dreamed of starting a family business and in 2019, he partnered with his brother, Bobby, to found Peter's Pizzeria. Peter's dedication to the craft and his love for quality ingredients has driven his passion to be the world's best Pizzaiolo, to be dubbed the future "Pizza King".</p>
  //   <p>But what does this even mean? It means, Peter has an unwavering dedication to delivering the best quality product for his customers, every single day. Pizza is comfort food, it's meant to be shared with family and friends or as a quick slice to go. In Peter's opinion, Long Island produces the best pizza in New York, possibly the world. Peter's vision is to be the go-to neighborhood pizzeria for families with their children and pizza aficionados alike. A meal at Peter's Pizzeria is local, inviting and fun - a place for everyone to gather over a quality slice.</p>
  //   <h2>PETER'S PROMISE</h2>
  //   <p className='uppercase'>To always deliver the highest quality Long Island style pizza possible.</p>
  // </>


  const [refHero, refHeroInView] = useInView({ triggerOnce: true })


  const [refMenu, refMenuInView] = useInView({ triggerOnce: true })
  const [currentTab, setCurrentTab] = useState(0)

  // On Parent Tab Change
  function onTabChange(i, blank) {
    if (blank === (-1)) {
      setCurrentTab(i)
    } else {
      return false
    }
  }

  const [currentSliderOpen, setCurrentSliderOpen] = useState(0)

  const menuData = [
    {
      "Pizza": [
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
      ],
    },
    {
      "Salad": [
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
      ],
    },
    {
      "Entrees": [
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
      ],
    },
    {
      "Hot Subs · Rolls & Calzones": [
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
      ],
    },
    {
      "Dessert": [
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
      ],
    },
    {
      "Drinks": [
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
        {
          name: "Meat Lovers Pie",
          description: "Regular pie topped with meatball, sausage and pepperoni",
          price: 28,
          image: "https://picsum.photos/300/300"
        },
      ],
    }
  ]

  const menusControls = menuData?.map((node, i) => {
    return (
      <Fragment key={i}>
        {i > 0 ? <>/</> : null}
        <div className="tab-control-next-positioner hover-trigger" onClick={() => onTabChange(i, -1)} onKeyDown={() => onTabChange(i, -1)} role="button" tabIndex={0}>
          <span className={`text ${i === currentTab ? 'current' : null}`}>{Object.keys(node)[0]}</span>
        </div>
      </Fragment>
    )
  })

  function createGroups(arr, numGroups) {
    const perGroup = numGroups;
    return new Array(numGroups)
      .fill('')
      .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
  }

  const menusTab = menuData?.map((node, i) => {
    const menuItemsGrouped = createGroups(node?.[Object.keys(node)[0]], 4)
    //console.log('menuItemsGrouped',menuItemsGrouped)

    const menuItems = menuItemsGrouped?.map((nodenode, ii) => {
      const menuItemsSingle = nodenode?.map((nodenodenode, iii) => {

        return (
          <Fragment key={iii} >
            <Intro visible={refMenuInView} in={{ fadeBottom: 500 }} delayIn={ii * 4 * 75 + 75 * iii} mounted={true} stay={true} className={`menu-item menu-item-${iii + 1}`}>
              <div className="padd-quart">
                <div className="menu-item-description padd-half c0">
                  <h6>Meat Lovers Pizza</h6>
                  <p>Regular pie topped with meatball, sausage and pepperoni</p>
                  <h6 className="price">{formatter2dig.format(28)}</h6>
                </div>
                <div className="menu-item-image c1">
                  <StaticImage
                    src={'../../static/images/sections/about/magic3.jpg'}
                    layout="fixed"
                    objectFit="cover"
                    objectPosition={'50% 50%'}
                    placeholder='NONE'
                    alt="New York Flava Badge"
                  />
                </div>
              </div>
            </Intro>
            {iii === 1 ?
              <Intro key={iii} visible={refMenuInView} in={{ fadeBottom: 500 }} delayIn={ii * 4 * 75 + 75 * iii} mounted={true} stay={true} className={`menu-decor menu-decor-${iii + 1}`}>
                <div className="padd-quart">
                  <StaticImage
                    src={'../../static/images/sections/about/magic3.jpg'}
                    layout="fixed"
                    objectFit="cover"
                    objectPosition={'50% 50%'}
                    placeholder='NONE'
                    alt="New York Flava Badge"
                  />
                </div>
              </Intro>
              : null}
          </Fragment>
        )
      })

      return (
        <div key={ii} className='menu-group'>
          {menuItemsSingle}
        </div>
      )

    })


    return i === currentTab ? (
      <div key={i} className="menu-tab padd padd-top-quart padd-bottom-off">
        {menuItems}
      </div>
    ) : false
  })

  // const menus = menuDataDirectus?.map((node,i) => {
  //   return (
  //     <>
  //       <pre>
  //         <code>{JSON.stringify(node, null, 2)}</code>
  //       </pre>
  //     </>
  //   )
  // })


  const [refSlider, refSliderInView] = useInView({ triggerOnce: true })

  const SliderArrowNext = ({ to, onClick }) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-next-positiner hover-trigger`} aria-label={to}>
        {/* <div className="text">NEXT</div> */}
        <div className="icon"></div>
      </div>
    )
  }

  const SliderArrowPrev = ({ to, onClick }) => {
    return (
      <div role="button" onClick={onClick} className={`slider-control-prev-positiner hover-trigger`} aria-label={to}>
        <div className="icon"></div>
        {/* <div className="text">PREVIOUS</div> */}
      </div>
    )
  }

  const [refMarket, refMarketInView] = useInView({ triggerOnce: true })

  const settingsMarket = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <SliderArrowNext to="next" />,
    prevArrow: <SliderArrowPrev to="prev" />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  const settingsSize = {
    dots: false,
    infinite: false,
    speed: 500,
    draggable: false,
    nextArrow: <SliderArrowNext to="next" />,
    prevArrow: <SliderArrowPrev to="prev" />,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const marketItems = data?.sanityPageHome?.section_products?.map((item, i) => {

    const sizeSlides = item.size?.map((size, i) => {
      return <p key={i} className='fs-125'>{size}</p>
    })

    return (
      <div key={i} className="market-item">
        <Intro visible={refMarketInView} in={{ fadeLeft: 750 }} delayIn={200 * i} mounted={true} stay={true} className="">

          <div className={`aspect-ratio`}>
            <GatsbyImage
              image={item.image?.asset?.gatsbyImageData}
              alt={item.title}
              layout="fixed"
              objectFit="cover"
              objectPosition={'50% 50%'}
              placeholder='NONE'
            />
            {item.price ?
              <div className='price-tag'><span>{formatter.format(item.price)}</span></div>
              :
              null
            }
          </div>
          <div className="description uppercase c2">
            <div className='copy padd-half padd-bottom-off fs-85 text-center'>
              <h5 className='h6'>{item.title}</h5>
              <div className='thin'>{item.subtitle}</div>
            </div>
            <div className='buttons-group fs-85'>
              <div className="text-center">
                <div className="outlined pick-size-btn">
                  <span>
                    <div className='wrap'>
                      <p className='pick-p'>Pick your size</p>
                      <div className='slider'>
                        <Slider {...settingsSize}>
                          {sizeSlides}
                        </Slider>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div className="text-center">
                <a href={"#"} className="btn outlined colored"><span>Add to cart</span></a>
              </div>
            </div>
          </div>
          <div className="decor-b">
            <div className="decor-bl"></div>
            <div className="decor-bc"></div>
            <div className="decor-br"></div>
          </div>

        </Intro>
      </div>
    )
  })

  const marketSlider =
    <Slider {...settingsMarket}>
      {marketItems}
    </Slider>

  const marquee = 'SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE • SEE MORE •'


  const [refLocations, refLocationsInView] = useInView({ triggerOnce: true })

  // const settingsLocation = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   nextArrow: <SliderArrowNext to="next" />,
  //   prevArrow: <SliderArrowPrev to="prev" />,
  //   slidesToShow: 2,
  //   slidesToScroll: 2,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       }
  //     }
  //   ]
  // }

  const locations = data?.sanityPageHome?.section_locations?.map((node, i) => {
    return (
      <Intro key={i} visible={refLocationsInView} in={{ fadeLeft: 1000 }} delayIn={300 * i} mounted={true} stay={true} className={node?.title?.toLowerCase() === 'coming soon' ? "coming-soon-location" : ""}>
        <div className="location padd-top-half flex-12">
        <div className={`span-6 span-12-mobile flex fd-column img-column ${node?.title?.toLowerCase() === 'coming soon' ? "c2" : ""}`}>
            <div className={`aspect-ratio image-flex`}>
              <GatsbyImage
                image={node?.image?.asset?.gatsbyImageData}
                alt={`Peter's Pizza Location, ${node.title}`}
                layout="fixed"
                objectFit="cover"
                objectPosition={'50% 50%'}
                placeholder='NONE'
              />
            </div>
          </div>
          <div className='flex flex-nowrap fd-column overflow-hidden span-6 span-12-mobile'>
            <div className="decor-t">
              <div className="decor-tl"></div>
              <div className="decor-tc"></div>
              <div className="decor-tr"></div>
            </div>
            <div className="description flex-12 uppercase c2 ac-center jc-center">
              <Intro visible={refLocationsInView} in={{ fade: 500 }} delayIn={0} mounted={true} stay={true}>
                <h3 className="h4 text-center">{node?.title}</h3>
              </Intro>
              <div className=" details">
                <div className="">
                  <p className="text-center">
                    <a href={data?.sanitySiteSettings?.orderLink ? data?.sanitySiteSettings?.orderLink : "#"} target='_blank' rel="noopener noreferrer" className="btn outlined"><span>Order now</span></a>
                  </p>
                  <SanityContent _rawContent={node?._rawAddress?.[0]} />
                </div>
                <div className="padd-top-half">
                  <SanityContent _rawContent={node?._rawBody?.[0]} />
                </div>
              </div>
            </div>
            <div className="decor-b">
              <div className="decor-bl"></div>
              <div className="decor-bc"></div>
              <div className="decor-br"></div>
            </div>
          </div>
        </div>
      </Intro>
    )
  })

  const locationsSlider =
    // <Slider {...settingsLocation}>
    locations
  {/* </Slider> */ }


  return (
    <>

      <MainFrameCover />

      {/* <Modal trigger={"#modal-1st-login"}>
        <LoginBox />
      </Modal>

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal> */}

      <Modal trigger={"#modal-sign-up"}>
        <SignUpOfferModal />
      </Modal>

      <main id="mainframe" className={`active main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug}`}>

        <SEO title={"Peter's Pizza"} description={"New York Flava | Florida Made"} />

        <section ref={refHero} className="section-hero">
          <Intro visible={refHeroInView} in={{ appear: 0, bg: 1000 }} delayIn={0} mounted={true} stay={true} className="c0 t padd padd-right-off padd-left-off">

            <Intro visible={refHeroInView} in={{ fadeBottom: 800 }} delayIn={200} mounted={true} stay={true} className="hero-logo">
              <div className="aspect-ratio">
                <StaticImage
                  src={'../../static/images/sections/hero/hero.png'}
                  layout="fixed"
                  objectFit="cover"
                  objectPosition={'50% 50%'}
                  placeholder='NONE'
                  alt="Peter's Pizza, New York Flava - Florida Made"
                />
              </div>
            </Intro>

            <Intro visible={refHeroInView} in={{ fadeBottom: 800 }} delayIn={500} mounted={true} stay={true} className="text-center overflow-visible">
              <p className=""><a href={data?.sanitySiteSettings?.orderLink ? data?.sanitySiteSettings?.orderLink : "#"} target='_blank' style={{fontSize: '1.8em'}} rel="noopener noreferrer" className="btn blue"><span>Order Now</span></a></p>
            </Intro>

          </Intro>
          <div id="header-fold-breakpoint"></div>

        </section>


        {/* <section ref={refMenu} className="section-menu c5 padd padd-right-off padd-left-off">

          <div id="menu" className="anchor"></div>

          <Intro visible={refMenuInView} in={{ fadeBottom: 750 }} delayIn={0} mounted={true} stay={true} className="text-center">
            <h2 className="h1">Menu</h2>
          </Intro>


          <div className='menu-controls'>
            {menusControls}
          </div>

          <div className="menu-tabs">
            {menusTab}
          </div>

        </section> */}


        <section ref={refSlider} className="section-slider c4 padd padd-right-off padd-left-off">

          <div id="gallery" className="anchor"></div>

          <Intro visible={refSliderInView} in={{ fadeRight: 750 }} delayIn={500} mounted={true} stay={true} className="badge">
            <div className="aspect-ratio">
              <StaticImage
                src={'../../static/images/sections/menu/badge.png'}
                layout="fixed"
                objectFit="contain"
                objectPosition={'50% 50%'}
                placeholder='NONE'
                alt="New York Flava Badge"
              />
            </div>
          </Intro>

          <div className="sideinfo-left">
            <div className="rotate--90">
              <h6>NY Flava. FL Made</h6>
            </div>
          </div>
          <div className="sideinfo-right">
            <div className="rotate-90">
              <h6>Supa dupa fresh</h6>
            </div>
          </div>

          <Intro visible={refSliderInView} in={{ fadeBottom: 750 }} delayIn={100} mounted={true} stay={true} className="">
            <div className='controls flex jc-center padd-bottom-half'>
              <div className="tab-control tab-control-next-positioner hover-trigger padd-right-half" onClick={() => setCurrentSliderOpen(0)} onKeyDown={() => setCurrentSliderOpen(0)} role="button" tabIndex={0}>
                <span className={`text ${currentSliderOpen === 0 ? null : 'current'}`}>Photo Gallery</span>
              </div>
              <div className="tab-control tab-control-next-positioner hover-trigger padd-left-half" onClick={() => setCurrentSliderOpen(1)} onKeyDown={() => setCurrentSliderOpen(1)} role="button" tabIndex={0}>
                <span className={`text ${currentSliderOpen === 1 ? null : 'current'}`}>Social</span>
              </div>
            </div>
            <div className="slider">
              {currentSliderOpen === 0 ?
                <SanitySlider {...data?.sanityPageHome?.section_slider?.[0]} />
                :
                null}
              {currentSliderOpen === 1 ?
                <SanitySlider {...data?.sanityPageHome?.section_social?.[0]} />
                :
                null}
            </div>
          </Intro>

        </section>

{/* 
        <section ref={refMarket} className="section-market c3 padd padd-right-off padd-left-off">

          <div id="shop" className="anchor"></div>

          <Intro visible={refMarketInView} in={{ fadeBottom: 750 }} delayIn={0} mounted={true} stay={true} className="text-center">
            <h2 className="h1">Market</h2>
          </Intro>

          <div className='slider padd-top-half padd-bottom-half'>
            {marketSlider}
          </div>

          <div className="sideinfo-right">
            <div className="rotate-90">
              <h6>Pure Vibes for your day to day grind</h6>
            </div>
          </div>

          {marquee ?
            <div id="seemore-marquee" className="login c5 border-c5 t has-intro">
              <Intro visible={refMarketInView} in={{ border: 700, bg: 700, fade: 700 }} delayIn={200} className="active c5 t">
                <div className="marquee uppercase">
                  <div>
                    <div>{marquee}</div>
                    <div>{marquee}</div>
                    <div>{marquee}</div>
                    <div>{marquee}</div>
                  </div>
                </div>
              </Intro>
            </div>
            : null}

          <Intro visible={refAboutInView} in={{ fade: 750 }} delayIn={0} mounted={true} stay={true} className="pizza-slice">
            <div className="aspect-ratio">
              <StaticImage
                src={'../../static/images/sections/market/slice.png'}
                layout="fixed"
                objectFit="contain"
                objectPosition={'50% 50%'}
                placeholder='NONE'
                alt="Pizza Slice"
              />
            </div>
          </Intro>
        </section> */}

        <section ref={refLocations} className="section-locations c0 padd padd-bottom-half padd-right-off padd-left-off">

          <div id="locations" className="anchor"></div>

          <Intro visible={refLocationsInView} in={{ fadeBottom: 750 }} delayIn={0} mounted={true} stay={true} className="text-center">
            <h2 className="h1">Locations</h2>
          </Intro>
          <div className="sideinfo-left">
            <div className="rotate--90">
              <h6>EVERYONE IS FAMILY HERE. WELCOME!</h6>
            </div>
          </div>
          <div className="locations">
            {locationsSlider}
          </div>
        </section>

        <section ref={refAbout} className="section-about c3 padd-right-off padd-left-off">

          <div id="about" className="anchor"></div>

          <div className="flex-12">
            <div className="span-12">
              <div className="flex-12 ai-center">
                <div className="span-5 span-12-mobile overflow-visible position-relative">
                  <Intro visible={refAboutInView} in={{ fadeLeft: 750 }} delayIn={0} mounted={true} stay={true} className="about-magic-1">
                    <div className="aspect-ratio">
                      {/* <StaticImage
                        src={'../../static/images/sections/about/magic1.jpg'}
                        layout="fixed"
                        objectFit="cover"
                        objectPosition={'50% 50%'}
                        placeholder='NONE'
                        alt="Peter"
                      /> */}
                    <SanityImage {...data?.sanityPageHome?.image_history} />
                    </div>
                  </Intro>
                </div>
                <div className="span-7 span-12-mobile overflow-visible padd-left-half padd position-relative about-copy">
                  <Intro visible={refAboutInView} in={{ fadeRight: 750 }} delayIn={400} mounted={true} stay={true} className="">
                    {/* {aboutCopy} */}
                    <SanityContent _rawContent={data?.sanityPageHome?.copy_history?._rawContent} />
                  </Intro>
                  <Parallax className="about-magic-2 padd-top-half" translateY={[-10, 10]} tagouter="figure">
                    <Intro visible={refAboutInView} in={{ fadeBottom: 750 }} delayIn={500} mounted={true} stay={true} className="">
                      <div className="aspect-ratio">
                        <StaticImage
                          src={'../../static/images/sections/about/magic4.png'}
                          layout="fixed"
                          objectFit="contain"
                          objectPosition={'50% 50%'}
                          placeholder='NONE'
                          alt="Hello"
                        />
                      </div>
                    </Intro>
                  </Parallax>
                </div>
              </div>
            </div>
          </div>
          {/* <Parallax className="about-magic-3" translateY={[20, -20]} tagouter="figure">
            <Intro visible={refAboutInView} in={{ fadeBottom: 750 }} delayIn={600} mounted={true} stay={true} className="">
              <div className="aspect-ratio">
                <StaticImage
                  src={'../../static/images/sections/about/magic3.jpg'}
                  layout="fixed"
                  objectFit="cover"
                  objectPosition={'50% 50%'}
                  placeholder='NONE'
                  alt="Pizza"
                />
              </div>
            </Intro>
          </Parallax> */}
        </section>

        <section ref={refVideo} className="section-video c4 padd padd-right-2 padd-left-2">

          <div id="see" className="anchor"></div>

          <Intro visible={refVideoInView} in={{ fadeBottom: 750 }} delayIn={0} mounted={true} stay={true} className="">
            <SanityVideo {...data?.sanityPageHome?.section_video?.[0]} />
          </Intro>
        </section>

        <Footer />

      </main>

    </>
  )
}

export default Page

export const query = graphql`
  query {
    sanityPageHome {
      section_products {
        title
        subtitle
        price
        size
        slug {
          current
        }
        image {
          ...imgFullSanity
        }
      }
      copy_history {
        ...sanityContent
      }
      image_history {
        image {
          ...imgFullSanity
        }
      }
      section_locations {
        title
        image {
          ...imgFullSanity
        }
        _rawAddress(resolveReferences: {maxDepth: 5})
        _rawBody(resolveReferences: {maxDepth: 5})
      }
      section_slider {
        ...sanitySlider
      }   
      section_social {
        ...sanitySlider
      }   
      section_video {
        ...sanityVideo
      }
    }
    sanitySiteSettings {
      orderLink
    }
  }
`